
<template>
    <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center" id="page-login">
      <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
        
        <div class="vx-row no-gutter justify-center items-center">

            <div class="vx-col w-full md:w-full lg:w-1/3">
            <div class="p-8 login-tabs-container">
                
                <div>
                    <vs-input
                    data-vv-validate-on="blur"
                    v-validate="'required|min:4'"
                    name="dataClear"
                    icon-no-border
                    icon="icon icon-activity"
                    icon-pack="feather"
                    label-placeholder="Tasking"
                    v-model="dataClear"
                    class="w-full"/>
                    <span class="text-danger text-sm">{{ errors.first('dataClear') }}</span>

                    <div class="flex flex-wrap justify-between my-4 mx-auto">
                        <vs-button color="danger" to="/">Back</vs-button>
                        <vs-button @click="submitClear" :disabled="!validateForm">Clear</vs-button>
                    </div>
                    
                </div>

            </div>
            </div>
        </div>
          
      </div>
  
    </div>
  </template>
  
  <script>
  import { mapGetters } from 'vuex'
  export default{
  
    data() {
      return {
        dataClear: ''
      }
    },
    computed: {
      ...mapGetters({
        user  : 'auth/user'
      }),
      validateForm() {
        return !this.errors.any() && this.dataClear !== ''
      }
    },
    methods: {
        submitClear() {
            if (!this.errors.any()) {
                this.$vs.loading()
                let configs = {
                    headers: {
                        'Authorization': 'Bearer ' + this.user.api_token,
                    },
                }
                let formData = {
                    'type' : this.dataClear
                }
                this.axios.post('/clear-data', formData, configs)
                .then((response) => {
                    this.$vs.loading.close()
                    this.$vs.notify({
                        title: 'Success',
                        color  : 'success',
                        text  : response.data.message,
                    })
                })
                .catch((error) => {
                    this.$vs.loading.close()
                    let { data } = error.response
                    this.$vs.notify({
                        title: 'Error',
                        text: data.message,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                })
            }
        },
        initValues () {
            this.dataClear = ''
        },
    },
  }
  </script>
  